'use client'

import type { ReactNode } from 'react'
import GlobalSnackbar from 'containers/GlobalSnackbar'
import Header from './Header'

type AuthRootLayoutProps = {
  children: ReactNode
}

export default function AuthRootLayout(props: AuthRootLayoutProps) {
  return (
    <>
      <Header />
      {props.children}
      <GlobalSnackbar />
    </>
  )
}
