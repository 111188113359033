import { AppBar } from '@patrianna/core-components'
import LogoLink from 'src/containers/LogoLink'
import CloseButton from 'components/CloseButton'
import classes from './styles.module.scss'

export default function Header() {
  return (
    <AppBar className={classes.root} fixed>
      <h1 className={classes.logoContainer}>
        <LogoLink />
      </h1>

      <CloseButton />
    </AppBar>
  )
}
